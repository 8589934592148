import React from 'react'

// Third Party
import styled from 'styled-components'

const StyledArrow = styled.span`
  background-color: ${(props) => props.theme.color.light};
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 16px;
    height: 16px;
  }
  @media (min-width: 992px) {
    top: 5px;
    right: 5px;
    height: 34px;
    width: 34px;
  }
  @media (max-width: 991px) {
    top: 3px;
    right: 3px;
    height: 30px;
    width: 30px;
  }
`

const Arrow = () => (
  <StyledArrow>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="19"
      viewBox="0 0 13 19"
    >
      <path
        id="Stroke_3"
        data-name="Stroke 3"
        d="M0,0V17L11,8.5Z"
        transform="translate(1 1)"
        fill="none"
        stroke="#ff8300"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  </StyledArrow>
)

export default Arrow
