/* eslint-disable camelcase */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
}

const Block = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  margin-top: -100px;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.light};
  padding: 50px 100px;

  @media screen and (max-width: 991px) {
    padding: 20px 25px;
  }

  @media screen and (max-width: 350px) {
    padding: 20px 15px;
  }
`

const Text: React.FC<TextProps> = ({ fields }) => (
  <section className="mb-5 pb-3">
    <div className="container py-5">
      <Block>
        <ParseContent content={fields.description} />
      </Block>
    </div>
  </section>
)

export default Text
