/* eslint-disable camelcase */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import Share from 'components/elements/Share'

// Third Party
import styled from 'styled-components'

// Hooks
import useProject from 'hooks/useProject'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
}

const Block = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  margin-top: -300px;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.light};
  padding: 50px 100px;

  & h2 {
    font-size: ${props => props.theme.font.size.large};
    margin-bottom: 0;
  } 

  & h1,
  //& h2,
  & h3 {
    padding-left: 80px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px 25px;

    & h1,
    //& h2,
    & h3 {
      padding-left: 140px;
    }
  }

  @media screen and (max-width: 400px) {
    & h1,
    //& h2,
    & h3 {
      padding-left: 70px;
    }
  }

  @media screen and (max-width: 350px) {
    padding: 20px 15px;

    & h1,
    //& h2,
    & h3 {
      padding-left: 0px;
      padding-top: 10px;
      margin-bottom: -15px;
    }
  }
`

const ReadTime = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  font-size: 14px;
  text-align: right;
`

const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -55px;
  left: -55px;
  height: 220px;
  width: 220px;
  border: 5px solid ${({ theme }) => theme.color.secondary};
  border-radius: 110px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.light};

  & img {
    border-radius: 110px;
  }

  @media screen and (max-width: 991px) {
    height: 170px;
    width: 170px;
    top: -55px;
    left: -10px;
  }

  @media screen and (max-width: 400px) {
    height: 120px;
    width: 120px;
    top: -55px;
    left: -5px;
  }
`

const Text: React.FC<TextProps> = ({ fields, location }) => {
  const {
    details: { info },
  }: any = useProject()

  return (
    <section>
      <div className="container py-5">
        <Block>
          <ImageWrapper>
            <Plaatjie image={info?.image} alt="Teamlid" className="w-100" />
          </ImageWrapper>
          <ReadTime className="pb-4">{info?.readtime}</ReadTime>
          <ParseContent content={fields.description} />
          <div className="pt-sm-5 pt-3">
            <Share
              type="orange"
              postTitle="Bekijk dit bericht van E-office"
              title="Deel dit bericht"
              location={location}
            />
          </div>
        </Block>
        <div>
          <FlexContentHandler
            prefix="Fragment_FlexFragment"
            fields={fields.element?.flex_fragment}
            location={location}
          />
        </div>
      </div>
    </section>
  )
}

export default Text
