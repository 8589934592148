import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Arrow from 'components/elements/Arrow'
import Share from 'components/elements/Share'
import FingerPrint from 'components/elements/FingerPrint'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Images
import E from 'img/e.inline.svg'
import Employment from 'img/employment.inline.svg'
import Level from 'img/level.inline.svg'
import Check from 'img/check.svg'

// Hooks
import useVacature from 'hooks/useVacature'
import Plaatjie from '@ubo/plaatjie'
import { navigate } from 'gatsby'
import GravityForm from 'components/shared/GravityForm'

interface VacancyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location: any
}

const Block = styled.div<{ isFirstBlock: boolean }>`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.color.light};
  position: relative;
  z-index: 1;
  padding: 60px 100px 100px 100px;

  &.custom {
    padding: 60px 100px 350px 100px;

    @media (max-width: 991px){
      padding: 40px 40px 350px 40px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 85px 60px 1px 60px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 40px 1px 40px;
  }

  @media screen and (max-width: 575px) {
    padding: 85px 25px 1px 25px;
  }

  @media screen and (max-width: 350px) {
    padding: 85px 15px 1px 15px;
  }

  & ul {
    list-style-image: url(${Check});
  }
`

const FormWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.gradient.contrast.to};
    background: ${`rgb(${theme.color.gradient.to})`};
    background: ${`linear-gradient(140deg, rgba(${theme.color.gradient.contrast.from},1) 0%, rgba(${theme.color.gradient.contrast.to},1) 65%);`};
  `}

  padding: 50px 100px;
  width: 90%;
  left: 5%;
  border-radius: 20px;
  position: relative;
  margin-top: -300px;
  z-index: 2;

  & label {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-group {
    & a:hover {
      text-decoration: underline;
    }
  }

  & svg {
    opacity: 0.12;
    position: absolute;
    overflow-y: hidden;

    @media (min-width: 992px) {
      width: 800px;
      bottom: -150px;
      left: 50%;
    }

    @media (max-width: 991px) {
      width: 600px;
      bottom: -150px;
      left: 45%;
    }

    @media (max-width: 767px) {
      width: 400px;
      bottom: -250px;
      left: 30%;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 20px 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 15px 25px;
  }

  @media screen and (max-width: 350px) {
    padding: 15px 15px;
  }
`

const FormTitle = styled.div`
  font-size: 40px;
  color: ${({ theme }) => theme.color.light};
  font-family: ${({ theme }) => theme.font.family.secondary};

  @media screen and (max-width: 575px) {
    font-size: 36px;
    line-height: 36px;
  }
`

const Button = styled(motion.a)`
  ${(props) => css`
    background: rgb(${props.theme.color.gradient.contrast.to});
  `}

  cursor: pointer;
  border-radius: 25px;
  transition: all 0.3s ease;
  color: ${(props) => props.theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  text-decoration: none !important;

  &:hover {
    color: ${(props) => props.theme.color.light};
  }
  @media (min-width: 992px) {
    padding: 10px 60px 10px 35px;
    font-size: ${(props) => props.theme.font.size.medium};
    line-height: ${(props) => props.theme.font.size.large};
    white-space: nowrap;
  }
  @media (max-width: 991px) {
    padding: 10px 45px 10px 20px;
    font-size: ${(props) => props.theme.font.size.small};
    line-height: ${(props) => props.theme.font.size.medium};
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & img {
    max-height: 266px;
    object-fit: cover;
  }
`

const Container = styled.div``

const Title = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 62.5px;
      color: #676767;
    }
  }
`

const Info = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.family.main};
  color: #676767;
`

const InnerContent = styled(ParseContent)`
  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 20px !important;
    }
  }

  & p span a {
    color: #fff;
  }

  & iframe {
    margin: 0 auto;

    @media (max-width: 991px) {
      height: auto;
    }
  }
`

const Vacancy: React.FC<VacancyProps> = ({ fields, location }) => {
  const {
    details: { info },
    vacatureTypes,
  }: any = useVacature()

  const moveToApplyForm = () => {
    if (typeof document !== 'undefined') {
      document
        .getElementById('solliciteer-direct')
        ?.scrollIntoView({ behavior: 'smooth' })
      navigate('#solliciteer')
    }
  }

  const type = vacatureTypes ? vacatureTypes.nodes[0].name : ''

  return (
    <section>
      <Container className="container">
        <ImageWrapper>
          <Plaatjie image={fields.image} alt="" />
        </ImageWrapper>
        <Block isFirstBlock={fields.image !== null} className={`${fields?.newTemplate !== true && 'custom'}`}>
          <Title content={fields.title} />
          {fields.showinfo && (
            <div className="py-lg-5 pt-sm-5 pt-3 pb-4 d-flex flex-lg-nowrap flex-wrap align-items-center justify-content-between">
              <Info className="d-flex flex-wrap">
                {!!info && info.employment !== '-' && (
                  <>
                    <div className="mr-sm-5 mr-3 py-sm-0 py-2 d-flex align-items-center">
                      <Employment />
                      <div className="ml-2">{info.employment}</div>
                    </div>
                    <div className="mr-sm-5 mr-3 py-sm-0 py-2 d-flex align-items-center">
                      <Level />
                      <div className="ml-2">{info.level}</div>
                    </div>
                    <div className="d-flex py-sm-0 py-2 align-items-center">
                      <E />
                      <div className="ml-2">{type}</div>
                    </div>
                  </>
                )}
              </Info>

              <div className="pt-lg-0 pt-4">
                <Button
                  initial={{
                    background:
                      'linear-gradient(90deg,rgba(241,37,48, 1) 0%,rgba(255,132,0, 1) 50%)',
                  }}
                  whileHover={{
                    background:
                      'linear-gradient(90deg,rgba(241,37,48, 1) 20%,rgba(255,132,0, 1) 80%)',
                  }}
                  transition={{ type: 'spring' }}
                  onClick={() => moveToApplyForm()}
                >
                  Solliciteer direct
                  <Arrow />
                </Button>
              </div>
            </div>
          )}

          <InnerContent content={fields.description} />
          {fields.showshare && (
            <div className="py-lg-5 pt-3 pb-5 d-flex flex-lg-nowrap flex-wrap justify-content-between">
              <div className="pb-lg-0 pb-4">
                <Button
                  initial={{
                    background:
                      'linear-gradient(90deg,rgba(241,37,48, 1) 0%,rgba(255,132,0, 1) 50%)',
                  }}
                  whileHover={{
                    background:
                      'linear-gradient(90deg,rgba(241,37,48, 1) 20%,rgba(255,132,0, 1) 80%)',
                  }}
                  transition={{ type: 'spring' }}
                  onClick={() => moveToApplyForm()}
                >
                  Solliciteer direct
                  <Arrow />
                </Button>
              </div>
              <div>
                <Share
                  type="orange"
                  postTitle="Bekijk dit bericht van E-office"
                  title="Deel dit bericht"
                  location={location}
                />
              </div>
            </div>
          )}

          {/* {fields.element && (
            <div>
              <FlexContentHandler
                prefix="Fragment_FlexFragment"
                fields={fields.element?.flex_fragment}
                location={location}
              />
            </div>
          )} */}
        </Block>
        {fields?.newTemplate !== true && (
          <FormWrapper id="solliciteer-direct">
            <FingerPrint />
            <FormTitle className="pb-lg-5 pb-3">Direct solliciteren</FormTitle>
            <GravityForm id={3} />
          </FormWrapper>
        )}
      </Container>
    </section>
  )
}

export default Vacancy
