/* eslint-disable camelcase */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Share from 'components/elements/Share'

// Third Party
import styled from 'styled-components'

// Hooks
import useNews from 'hooks/useNews'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
}

const Block = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  margin-top: -300px;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.light};
  padding: 50px 100px;

  @media screen and (max-width: 991px) {
    padding: 20px 25px;
  }

  @media screen and (max-width: 350px) {
    padding: 20px 15px;
  }
`

const ReadTime = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  font-size: 14px;
  text-align: right;
`

const Text: React.FC<TextProps> = ({ fields, location }) => {
  const {
    details: { info },
  }: any = useNews()

  return (
    <section className="mb-5 pb-3">
      <div className="container py-5">
        <Block>
          <ReadTime className="pb-4">{info.readtime}</ReadTime>
          <ParseContent content={fields.description} />
          <div className="pt-5">
            <Share
              type="orange"
              postTitle="Bekijk dit bericht van E-office"
              title="Deel dit bericht"
              location={location}
            />
          </div>
        </Block>
      </div>
    </section>
  )
}

export default Text
