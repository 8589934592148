import React from 'react'

// Components
import Text from 'components/flex/Text/Text'
import Vacancy from 'components/flex/Text/Vacancy'
import News from 'components/flex/Text/News'
import Project from 'components/flex/Text/Project'
import Privacy from 'components/flex/Text/Privacy'

interface TextShellProps {
  fields: any
  location?: any
}

interface TextProps {
  [key: string]: any
}

const TextShell: React.FC<TextShellProps> = ({ fields, location = {} }) => {
  const types: TextProps = {
    text: Text,
    vacancy: Vacancy,
    news: News,
    project: Project,
    privacy: Privacy,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextShell
