import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <ParseContent content={fields.description} />
    </div>
  </section>
)

export default Text
