import React from 'react'

// Images
import Linkedin from 'img/linkedin.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Whatsapp from 'img/whatsapp.inline.svg'
import Email from 'img/email.inline.svg'

// Third Party
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share'
import styled, { css } from 'styled-components'

interface ShareProps {
  title: string
  postTitle: string
  location: any
  type: 'orange' | 'white'
}

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: 14px;
`

const StyledShare = styled.div<{ type: string }>`
  ${Title} {
    ${({ type, theme }) =>
      type === 'orange' &&
      css`
        color: ${theme.color.secondary};
      `};

    ${({ type, theme }) =>
      type === 'white' &&
      css`
        color: ${theme.color.light};
      `};
  }

  & svg {
    ${({ type }) =>
      type === 'white' &&
      css`
        & path {
          fill: ${({ theme }) => theme.color.light} !important;
        }
      `};
    &:hover {
      opacity: 0.82;
    }
  }
`

const Share: React.FC<ShareProps> = ({
  type = 'orange',
  title,
  postTitle,
  location,
}) => {
  const shareUrl = location ? location.href : ''

  const removeHTML = (content: any) => {
    let newContent = content

    if (typeof document !== 'undefined') {
      const titleHtml = newContent
      const div = document.createElement('div')
      div.innerHTML = titleHtml
      newContent = div.innerText
    }

    return newContent
  }

  return (
    <StyledShare type={type} className="d-flex align-items-center">
      <Title className="pr-sm-4 pr-3">{title}</Title>
      <div>
        <LinkedinShareButton className="mr-2" url={shareUrl}>
          <Linkedin />
        </LinkedinShareButton>
        <FacebookShareButton className="mr-2" url={shareUrl}>
          <Facebook />
        </FacebookShareButton>
        <WhatsappShareButton className="mr-2" url={shareUrl}>
          <Whatsapp />
        </WhatsappShareButton>
        <EmailShareButton
          url={shareUrl}
          subject={removeHTML(postTitle)}
          body={removeHTML(shareUrl)}
        >
          <Email />
        </EmailShareButton>
      </div>
    </StyledShare>
  )
}

export default Share
